@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body{
    margin: 0;
    padding: 0;
    list-style: none;
    /* font-family: "Poppins", sans-serif; */
}

/* Hero Section Start */


.home{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8rem;
    background: #A8DF8E;
    min-height: 100vh;
    padding: 5rem 9% 5rem;
}

.home .home-content h1{
    font-size: 3.75rem;
    font-weight: 700;
    line-height: 1.3;
    color: #fff;
}

span{
    color: blue;
}

.home-content h3{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
    color: #fff;
}



.home-img img{
    /* position: relative;
    width: 30vw; */
    width: 100%;
    max-width: 450px;
    height: auto;

}

.home-content p{
    font-size: 1.125rem;
    font-weight: 500;
    color: #fff;
}

.btn{
    text-transform: uppercase;
    border-radius: 1rem;
    text-decoration: none;
    font-size: 1rem;
    color: white;
    font-weight: 700;
    background: #0A6847;
    padding: 1em 2.1em 1.1em;
    transition: 0.3s;
    cursor: pointer;
}

.btn:hover{
    background: #41B06E;
}

.hero-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    /* margin-top: 20px; */
}

.hero-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
}

.hero-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}

.facebook-bg{
    background: #3B5998;
}

.instagram-bg{
    background: #e1306c;
}

.youtube-bg{
    background: #ff0000;
}

.tiktok-bg{
    background: #000000;
}

.linkedin-bg{
    background: #0077b5;
}   

@media (max-width:1000px){
    .home{
        gap: 4rem;
    }
}
@media (max-width:995px) {
    .home{
        flex-direction: column;
        /* margin: 5rem 4rem; */
    }
    .home .home-content h3{
        font-size: 1.5625rem;
    }
    .home-content h1{
        font-size: 3.125rem;
    }
    /* .home-img img{
        width: 43.75vw;
        margin-top:0rem;
    } */
}

@media (max-width:768px){

}

/* Hero Section End */




/* about section start */


.about-section{
    width: 100%;
    min-height: 94vh;
    background-color: #FEFDED;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-section .about-content img{
    height: auto;
    width: 600px;
    max-width: 100%;
}

.about-text{
    width: 550px;
    max-width: 100%;
    padding: 0 10px;
}

.about-content{
    width: 1280px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.about-text h1{
    color: #0A6847;
    font-size: 85px;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.about-text h5{
    color: black;
    font-size: 25px;
    margin-bottom: 25px;
    text-transform: capitalize;
    letter-spacing: 2px;
}

.about-text p{
    color: black;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1px;
    margin-bottom: 45px;
    text-align: justify;
}


.about-section .about-btn{
    padding: .7rem 3rem;
    font-size: 1.1rem;
    color: #fff;
    background: red;
    border-radius: 5rem;    
    /* margin-top: -10rem; */
    cursor: pointer;
    box-shadow: 0 .1rem .3rem rgba(0, 0, 0, .3);
    position: relative;
    overflow: hidden;
    z-index: 0;
    border: 0;
}

.about-btn::before{
    content: '';
    position: absolute;
    top: -1rem; left: -100%;
    background: #fff9;
    height: 150%;
    width: 20%;
    transform: rotate(25deg);
    z-index: -1;
}

.about-btn:hover::before{
    transition: .3s linear;
    left: 120%;
}


/* .about-section .about-text a{
    background-color: #498e8a;
    color: white;
    border: 2px solid transparent;
    text-decoration: none;
    padding: 10px 30px;
    border-radius: 30px;
    font-size: 17px;
    font-weight: bold;
}

.about-section .about-text a:hover{
    transform: scale(1.2);
    background-color: transparent;
    border: 2px solid #498e8a;
    color: #498e8a;
    transition: .4s;
    cursor: pointer;
} */

@media screen and (max-width: 1180px) {
    .about-section{
        width: 100%;
        height: auto;
        padding: 70px 0px;
    }
}

@media screen and (max-width: 650px) {
    .about-section .about-content img{
        margin-bottom: 35px;
    }
    .about-text h1{
        font-size: 60px;
        margin-bottom: 25px;
    }
}


/* about section end */



/* Category Section Start */


.category-section{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    background: #A8DF8E;
}

.category-container{
    position: relative;
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.category-container .serviceBox{
    position: relative;
    width: 500px;
    height: 280px;
    background: #41B06E;
    border-radius: 20px;
    overflow: hidden;
}

.category-container .serviceBox .icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--i);
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition-delay: 0.25s;
}

.category-container .serviceBox:hover .icon{
    top: 30px;
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    transition-delay: 0s;
}

.category-container .serviceBox .icon img{
    font-size: 5em;
    color: #fff;
    transition: 0.5s;
    transition-delay: 0.25s;
    width: 10rem;
}

.category-container .serviceBox:hover .icon img{
    font-size: 2em;
    transition-delay: 0s;
    width: 2rem;
}

.category-container .serviceBox .content{
    position: relative;
    padding: 20px;
    color: #fff;
    text-align: center;
    margin-top: 100px;
    z-index: 1;
    transform: scale(0);
    transition: 0.5s;
    transition-delay: 0s;
}

.category-container .serviceBox:hover .content{
    transform: scale(1);
    transition-delay: 0.25s;
}

.category-container .serviceBox .content h2{
    margin-top: 10px;
    margin-bottom: 5px;
}

.category-container .serviceBox .content p{
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5em;
}
/* 
@media (max-width: 1080px) {
	.category-container .serviceBox{
        width: 400px;
        height: 280px;
    }
}

@media (max-width: 900px){
    .category-container{
       min-height: 45rem;
    }
    .category-container .serviceBox{
        
    }
}
 */

 @media (max-width: 1100px){
    .category-container{
        flex-direction: column;
    }
 }
 /* @media (max-width: 1100px){
    .category-container .serviceBox{
        width: 300px;
    }
} */

@media (max-width: 600px){
    .category-container .serviceBox{
        width: 300px;
    }
}

/* Category Section Start */


/* after event start */

.after-event-section{
    min-height: 100vh;
    background: #fff;
    padding: 5rem 9% 5rem;
}

.after-event-row{
    /* display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}

/* @media (max-width: 768px){
    .after-event-row{
        flex-direction: column;
    }
} */

.after-event-section .after-event-heading h1{
    text-align: center;
}

.after-event-section .after-event-heading h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.after-event-section .after-event-heading h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #0A6847;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.after-event-section p{
    text-align: justify;
    font-size: 1rem;
    align-items: center;
}

/* after event end */


/* Contact Section Start */

.contact-section{
    line-height: 1.7;
    color: #fff;
    background: #A8DF8E;
    padding: 6rem 0;
}

.container{
    width: 80%;
    margin: 0 auto;
}

.contact-section h2{
    text-align: center;
    margin-bottom: 4rem;
    line-height: 1.2;
    font-size: 2rem;
}

.contact-section img{
    width: 100%;
    display: block;
    object-fit: cover;
}

.contact-section .btn{
    display: inline-block;
    background: #fff;
    color: #000;
    padding: 1rem 2rem;
    border: 1px solid transparent;
    font-weight: 500;
    transition: all 400ms ease;
}

.contact-section .btn:hover{
    background: #A8DF8E;
    color: #fff;
    border-color: #fff;
}

.contact-section .btn-primary{
    background: #0A6847;
    color: #fff;
}

.contact__container{
    background: #41B06E;
    padding: 4rem;
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 4rem;
    height: 30rem;
    margin: 7rem auto;
    border-radius: 1rem;
}

.contact__aside{
    background: #0A6847;
    padding: 3rem;
    border-radius: 1rem;
    position: relative;
    bottom: 10rem;
}

.aside__image{
    width: 12rem;
    margin-bottom: 2rem;
}

.contact__aside h2{
    text-align: left;
    margin-bottom: 1rem;
}

.contact__aside p{
    font-size: 0.9rem;
    margin-bottom: 2rem;
}

.contact__details li{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    list-style: none;
}

.contact__details li i{
    font-size: 1.3rem;
}

.contact__details li a{
    text-decoration: none;
    color: #fff;
    transition: all 400ms ease;
}

.contact__details li a:hover{
    color: #A8DF8E;
}

.contact__socials{
    display: flex;
    gap: 0.8rem;
    margin-top: 3rem;
}

.contact__socials li{
    list-style: none;
}

.contact__socials a{
    background: #424890;
    padding: 1.25rem;
    border-radius: 50%;
    font-size: 0.9rem;
    transition: all 400ms ease;
}

.contact__socials a:hover{
    background: transparent;
}

.contact__form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-right: 4rem;
}

.form__name{
    display: flex;
    gap: 1.2rem;
}

.contact-section input,
.contact-section textarea{
    width: 100%;
    padding: 1rem;
    background: #fff;
    color: #000;
    border-radius: 1rem;
    outline: none;
    border: 2px solid #0A6847;
    outline: none;
}

.contact-section input::placeholder,
.contact-section textarea::placeholder{
    color: #000;
    font-family: "Poppins", sans-serif;
}

.contact__form .btn{
    width: max-content;
    margin-top: 1rem;
    cursor: pointer;
}

.contact-social-icon{
    margin-top: 1.5rem;
}

.contact-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.contact-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
}

.contact-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}

.facebook-bg{
    background: #3B5998;
}

.instagram-bg{
    background: #e1306c;
}

.youtube-bg{
    background: #ff0000;
}

.tiktok-bg{
    background: #000000;
}

.linkedin-bg{
    background: #0077b5;
}   


@media screen and (max-width: 1300px) {
    .contact{
        padding-bottom: 0;
    }
    .contact__container{
        gap: 1.5rem;
        margin-top: 3rem;
        height: auto;
        padding: 1.5rem;
    }
    .contact__aside{
        width: auto;
        padding: 1.5rem;
        bottom: 0;
        font-size: .9rem;
    }
    .contact__form{
        align-self: center;
        margin-right: 1.5rem;
    }
}

@media screen and (max-width: 990px) {
    .contact__container{
        grid-template-columns: 1fr;
        gap: 3rem;
        margin-top: 0;
        padding: 0;
    }
    .contact__form{
        margin: 0 3rem 3rem;
    }
}

@media screen and (max-width: 420px) {
    .container{
        width: 100%;
    }
}

/* Contact Section End*/


/* Newsletter Section Start */

.newsletter-section{
    background: linear-gradient(278.54deg, #41B06E 15.93%, #0A6847 98.7%);
    height: 80vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding: 0 15px;
}

button:focus, input:focus, textarea:focus {
	outline: 0;
}

.newsletter-section .newsletter-h2 {
	font-size: 25px;
	color: #f7f7f7;
	font-weight: 700;
	position: relative;
	text-transform: uppercase;
	margin-bottom: 40px;
}

.newsletter-section .newsletter-h2::before {
    content: "";
    position: absolute;
    background: #DDDDDD;
    width: 90px;
    height: 1px;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
}

.newsletter-section .newsletter-p {
	font-size: 15px;
	color: #f7f7f7;
	line-height: 24px;
	margin-bottom: 20px;
}

.newsletter-section .newsletter-form {
	width: 500px;
	height: 40px;
	/* border: 1px solid #ffffff; */
	position: relative;
	background: transparent;
}

.newsletter-section .newsletter-form .newsletter-input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
	/* border: transparent; */
	padding-left: 15px;
	color: #ffffff;
    border-radius: 1rem;
    border: 1px solid #fff;
    font-family: "Poppins", sans-serif;
}

.newsletter-section .newsletter-form .newsletter-input::placeholder {
	color: #ffffff;
}

.newsletter-section .newsletter-form button {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	color: #ffffff;
	background: #0A6847;
	font-weight: 700;
	text-transform: uppercase;
	padding: 0 20px;
	border: transparent;
	border-left: 1px solid #ffffff;
	cursor: pointer;
	transition: .3s;
    border-top-right-radius: .9rem;
    border-bottom-right-radius: .9rem;
}

.newsletter-section .newsletter-form button:hover {
	background: #A8DF8E;
}

@media (max-width: 575px) {
	.newsletter-section .newsletter-form {
	    width: 100%;
	}
}



/* Newsletter Section End */


/* logo section start */

.brand-logo-section{
    width: 100%;
    display: grid;
    place-items: center;
    /* background: #f2f2f2; */
}

.brand-logo-section .brand-logo-container h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.brand-logo-section .brand-logo-container h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #0A6847;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.brand-logo-section .brand-logo-container{
    width: 90%;
    height: auto;
    text-align: center;
}

.brand-logo-section .brand-logo{
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
}

.brand-logo-section .brand-logo ul{
    padding: 0;
    margin: 0;
}

.brand-logo-section .brand-logo ul li{
    list-style: none;
    display: inline-block;
    height: 100px;
    cursor: pointer;
    transition: .5s;
}

.brand-logo-section .brand-logo ul li img{
    width: 100%;
    height: 100%;
}

/* logo section end */