
footer {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 80px;
    background-color: #A8DF8E;
  }
  
  .container {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 12px;
  }
  
  .wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .wrapper .footer-widget {
    width: calc(20% - 30px);
    margin: 0 15px 50px;
    padding: 0 12px;
  }
  
  .wrapper .footer-widget:nth-child(1) {
    width: calc(40% - 50px);
    margin-right: 15px;
  }
  
  .wrapper .footer-widget .logo {
    margin-bottom: 30px;
    vertical-align: middle;
  }
  
  .wrapper .footer-widget p {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }
  
  .wrapper .footer-widget .socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .wrapper .footer-widget .socials li {
    list-style: none;
  }
  
  .wrapper .footer-widget .socials li a {
    width: 44px;
    height: 44px;
    margin-right: 10px;
    color: #ffffff;
    background-color: #0A6847;
    border-radius: 50%;
    font-size: 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;
  }
  
  .wrapper .footer-widget .socials li a:hover {
    background-color: #41B06E;
  }
  
  .wrapper .footer-widget h6 {
    color: #fff;
    margin: 10px 0 35px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .wrapper .footer-widget .links li {
    list-style: none;
  }
  
  .wrapper .footer-widget .links li a {
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    /* text-transform: capitalize; */
    line-height: 32px;
    transition: all 0.3s ease-out;
  }
  
  .wrapper .footer-widget .links li a:hover {
    color: #0A6847;
  }
  
  .copyright-wrapper {
    padding: 20px 0;
    border-top: 1px solid rgba(88, 89, 120, 0.4);
    background: #0A6847;
  }
  
  .copyright-wrapper p {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  
  .copyright-wrapper p a {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease-out;
  }
  
  .copyright-wrapper p a:hover {
    color: #037ef3;
  }
  
  /* Let's write the media query */
  @media (max-width: 992px) {
    .container {
      max-width: 960px;
    }
  
    .wrapper .footer-widget,
    .wrapper .footer-widget:nth-child(1) {
      width: calc(50% - 30px);
    }
  }
  
  @media (max-width: 768px) {
    .container {
      max-width: 720px;
    }
  
    .wrapper .footer-widget,
    .wrapper .footer-widget:nth-child(1) {
      width: 100%;
      margin: 0 10px 50px;
    }
  }
  